import { GetServerSideProps } from 'next'

import { TerminalError } from '../src/components'
import { CheckoutComponent } from '../src/model'
export const ErrorPage: CheckoutComponent = () => {
  return (
    <>
      <TerminalError />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  }
}

export default ErrorPage

// The error page can't require any session auth data, in case there was an error loading
// or creating it, so skip it completely
ErrorPage.requireAuth = false
ErrorPage.skipSessionAuth = true
